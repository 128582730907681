.material_page {
    .nav-pills {
        .nav-link {
            color: $az-black;

            &.active {
                background-color: $az-eage-color;
                color: $az-black;
            }
        }
    }
}

.cust-bdr-material {
    border-radius: 2px 2px 0 0;
    border-left-width: 1px;
    border-right-width: 1px;
    padding: 24px;
    background-color: #fff;
    border: 1px solid #E5EAF2;
    // text-align: center;
    // display: flex;
}

.cust-bdr-material-code {
    overflow: auto;
    margin-top: -1px;
    background-color: $az-btn-green;
    border: 1px solid #E5EAF2;
    -webkit-print-color-scheme: dark;
    color-scheme: dark;
    border-radius: 0 0 2px 2px;
    padding: 24px;

    pre {
        color: $az-white;
        margin-bottom: 0;
    }
}

.img-fluid-cust {
    max-width: 400px;
}

.btn-cust-small-o {
    @include add-button;
}

.btn-cust-small {
    @include common-green-btn;
    display: inline;
}

.btn-cust-medium-o {
    @include modalbtn-outline;
    margin-right: 0 !important;
}

.btn-cust-medium {
    @include modalbtn-delete;
    margin-left: 0 !important;

    &:hover {
        color: $az-white !important;
        font-weight: normal;
        outline: none !important;
        border: 2px solid $az-black !important;
        background: $az-black !important;
        color: $az-white !important;
        box-shadow: none !important;

        img {
            filter: invert(8) contrast(3) !important;
        }

    }

    &:focus {
        color: $az-white !important;
        font-weight: normal;
        outline: none !important;
        border: 2px solid $az-black !important;
        background: $az-black !important;
        color: $az-white !important;
        box-shadow: none !important;

        img {
            filter: invert(8) contrast(3) !important;
        }
    }

    &:active {
        color: $az-white !important;
        font-weight: normal;
        outline: none !important;
        border: 2px solid $az-black !important;
        background: $az-black !important;
        color: $az-white !important;
        box-shadow: none !important;

        img {
            filter: invert(8) contrast(3) !important;
        }
    }


}

.btn-cust-delete-icon {
    @include deleteicon
}

.theme-font-lato {
    font-family: $az-heading-two;
}

.theme-font-lato-b {
    font-family: $az-heading-para;
}

.text-cust-lightgray{
    color: $az-light-gray;
}

.text-cust-gray{
    color: $az-gray;
}

.text-cust-black{
    color: $az-black;
}

.text-cust-white{
    color: $az-white;
}

.bg-cust-black{
    background: $az-black;
}

.font-materials{
    font-size: $az-font-16;
}

.theme-font-OrpheusPro{
    font-family: $az-heading-one;
}

.text-danger {
    color: #dc3545!important;
}

.bg-cust-green{
    background: $az-btn-green;
}

.bg-cust-white{
    background-color: $az-white;
}

.bg-cust-theme{
    background-color: $az-eage-color;
}

.bg-cust-lightblack{
    background-color: $az-light-black;
}

.view-link{
    color: #1890ff !important;
    &:hover{
        color: #40a9ff !important;
    } 
}