/* ###### 6.4 Header  ###### */

.az-header-menu-show {
  @include media-breakpoint-down(md) {
    overflow: hidden;

    .az-header-menu {
      border-right: 1px solid rgba($gray-900, 0.08);
      box-shadow: 0 0 30px rgba($gray-900, 0.08);
      transform: translateX(0);

      @media (max-width:1023px) {
        width: calc(100% - 350px) !important;
        transition: all 0.2s linear 0s;
        transform: translateX(0px);
        height: calc(100vh - 65px);
        border-right: none !important;
        box-shadow: none !important;
        padding-bottom: 100px;
      }

      @media (max-width:991px) {
        width: calc(100% - 350px) !important;
        transition: all 0.2s linear 0s;
        transform: translateX(0px);
        height: calc(100vh - 65px);
        border-right: none !important;
        box-shadow: none !important;
        padding-bottom: 80px;
      }

      @media (max-width:767px) {
        width: calc(100% - 146px);
        transition: all 0.2s linear 0s;
        transform: translateX(0px);
        height: calc(100vh - 65px);
        border-right: none !important;
        box-shadow: none !important;
        padding-bottom: 80px;
      }

      @media (max-width:540px) {
        width: calc(100% - 146px) !important;
        transition: all 0.2s linear 0s;
        transform: translateX(0px);
        height: calc(100vh - 65px);
        border-right: none !important;
        box-shadow: none !important;
        padding-bottom: 80px;
      }

      @media (max-width:360px) {
        width: calc(100% - 109px) !important;
        transition: all 0.2s linear 0s;
        transform: translateX(0px);
        height: calc(100vh - 65px);
        border-right: none !important;
        box-shadow: none !important;
        padding-bottom: 80px;
      }
    }

    .az-navbar-backdrop {
      visibility: visible;
      opacity: 1;
    }
  }
}

.az-sidebar {
  width: 270px;
  @include transition(all 0.35s);
}

.az-header {
  height: $az-height-header-mobile;
  background-color: #fff;
  box-shadow: 0 0 30px rgba($gray-900, 0);
  border-bottom: 1px solid rgba($gray-900, 0.12);

  width: 180px;
  max-width: 180px;
  flex: 0 0 180px;
  min-height: calc(100vh - 70px);
  position: fixed !important;
  background: #ffffff;
  z-index: 9999;
  box-shadow: none;
  left: 0;
  padding: 0 !important;
  // border-right: 1px solid #72818e33;
  overflow-y: auto;
  min-height: calc(100vh - 44px);
  position: relative;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    position: relative;
    z-index: 100;
    height: auto;
    border-bottom: none;
  }

  >.container,
  >.container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-up(lg) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  >.container {
    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    @include media-breakpoint-down(md) {
      max-width: none;
    }
  }

  .az-header-left .az-logo {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      padding: 10px 20px;
    }
  }

  @media (max-width:1023px) {
    background: none;
    width: 100%;
    max-width: 100%;
  }

  @media (max-width:991px) {
    background: none;
    width: 100%;
    max-width: 100%;
  }

  @media (max-width:767px) {
    background: none;
  }

  @media (max-width:991px) { 
    background: none;
  }

  @media (max-width:767px) { 
    background: none;
  }

}

.az-header-left {
  display: block;
  align-items: center;

  .az-header-arrow {
    display: none;
    position: relative;
    top: -2px;
  }
}

.az-logo {
  font-weight: 700;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  text-transform: lowercase;
  color: $az-color-primary;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  position: relative;
  top: -2px;

  @include hover-focus() {
    color: $az-color-primary;
  }

  i {
    display: block;
    line-height: 0.9;
    margin-right: 8px;
    font-size: 40px;

    &::before {
      width: auto;
      height: auto;
    }
  }
}

.az-header-menu-icon {
  margin-right: 10px;
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(lg) {
    margin-right: 25px;
  }

  span {
    display: block;
    position: relative;
    width: 15px;
    height: 2px;
    background-color: $gray-900;
    @include border-radius();
    @include transition($transition-base);

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 20px;
      height: 2px;
      background-color: $gray-900;
      @include border-radius();
      @include transition($transition-base);
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }
}

.az-header-menu {
  position: relative !important;
  top: 0;
  bottom: 0;
  left: 0;
  width: $az-width-sidebar;
  background-color: #fff;
  overflow-y: auto;
  z-index: 9999;
  transform: translateX(0);
  @include transition(all 0.35s);
  padding: 20px;
  height: 100vh;

  position: absolute !important;
  overflow-x: auto;
  width: 100% !important;
  height: calc(100vh - 100px);

  scrollbar-width: 3px;
  scrollbar-color: #b7b7b7 #e9e9e9;
  -webkit-scrollbar-width: 3px;
  -moz-scrollbar-width: 3px;


  
  &::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

&::-webkit-scrollbar-track {
  background-clip: content-box;
  border: 0px solid transparent;
  width: 3px;
  background-color: #e9e9e9;
}

&::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
}

&::-webkit-scrollbar-thumb:hover {
  background-color: #b7b7b7;
}

&::-webkit-scrollbar-corner,
&::-webkit-scrollbar-track {
  background-color: #e9e9e9;
  width: 3px;
}

  @include media-breakpoint-up(lg) {
    align-self: stretch;
    background-color: transparent;
    border-right: 0;
    position: static;
    width: auto;
    overflow-y: visible;
    transform: none;
    z-index: auto;

    >.nav {
      width: 100%;

      .nav-item {
        display: block;
        align-self: stretch;

        &.dropdown {
          position: static;
        }

        &.dropdown-mega {
          position: static;
        }
      }
    }
  }

  >.nav {
    flex-direction: column;
    padding: 20px;

    @include media-breakpoint-up(lg) {
      padding: 0;
      flex-direction: column;
      align-items: center;
    }

    @media(max-width: 1023px) {
      padding: 0;
    }

    @media(max-width: 991px) {
      padding: 0;
    }

    @media(max-width: 767px) {
      padding: 0;
    }

  }

  .az-sidebar-loggedin {
    padding: 10px 0;
  }

  nav {
    &.az-menu-sub {
      &.dropdown-menu {
        @include media-breakpoint-down(md) {
          position: static !important;
          transform: translate(0) !important;
          border-width: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  .nav-item {
    &::before {
      content: "";
      position: absolute;
      top: $az-height-header + 3px;
      margin-left: 25px;
      width: 15px;
      height: 15px;
      border-top: 2px solid rgba($gray-900, 0.12);
      border-left: 2px solid rgba($gray-900, 0.12);
      background-color: #fff;
      transform: rotate(45deg);
      z-index: 10001;
      display: none;
    }

    +.nav-item {
      margin-top: 10px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 0px;
      }
    }

    >.nav-link {
      display: flex;
      align-items: center;
      position: relative;
      color: $body-color;
      font-size: 15px;
      font-weight: 500;
      padding: 0;
      cursor: pointer;
      height: 40px;
      border-bottom: 1px solid $border-color;
      @include transition($transition-base);

      @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
      }

      @include hover() {
        color: $az-color-primary;

        i {
          color: $az-color-primary;
        }
      }

      &.with-sub::after {
        content: "\f3d0";
        display: inline-block;
        font-family: "Ionicons";
        font-size: 12px;
        font-weight: 400;
        margin-left: auto;
        margin-top: 3px;
        opacity: 0.5;
        border: 0;

        @include media-breakpoint-up(lg) {
          margin-left: 5px;
        }
      }

      .typcn {
        font-size: 24px;
        line-height: 0.95;
        margin-right: 15px;
        width: 16px;

        @include media-breakpoint-up(lg) {
          font-size: 24px;
          margin-right: 7px;
        }

        &::before {
          width: auto;
        }
      }
    }

    &.active {
      >.nav-link {
        color: $az-color-primary;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          top: 0;
          left: -20px;
          width: 2px;
          background-color: transparent;
          @include border-radius();

          @include media-breakpoint-up(lg) {
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            width: auto;
            height: 2px;
          }
        }
      }
    }

    &.show {
      &::before {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
  }

  @media (max-width:1023px) {
    width: calc(100% - 600px) !important;
    right: inherit;
    left: 0;
    transition: all 0.2s linear 0s;
    transform: translateX(0px);
    background: $az-white;
  }

  @media (max-width:991px) {
    width: calc(100% - 600px) !important;
    right: inherit;
    left: 0;
    transition: all 0.2s linear 0s;
    transform: translateX(-250px);
  }

  @media (max-width:767px) {
    width: 60% !important;
    right: inherit;
    left: 0;
    transition: all 0.2s linear 0s;
    transform: translateX(-250px);
  }
}

.az-header-menu-header {
  height: $az-height-header-mobile;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid rgba($gray-900, 0.05);

  .close {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    .close {
      display: none;
    }
  }

  .close {
    font-weight: 300;
    font-size: 28px;
  }
}

.az-menu-sub {
  font-size: 13px;
  margin-left: 32px;
  margin-top: 12px;
  display: none;
  position: relative !important; // Overrideing react-bootstrap inline css
  transform: translate3d(0px, 0, 0px) !important; // Overrideing react-bootstrap inline css
  margin-bottom: 20px !important; // Overrideing react-bootstrap inline css

  @include media-breakpoint-down(md) {
    border: 0;
    position: static !important;
    transform: none !important;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 10px !important; // Overrideing react-bootstrap inline css
    background-color: #fff;
    padding: 15px 20px;
    border: none;
    border-left: 2px solid rgba($gray-900, 0.12);
    min-width: 200px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .nav {
    flex-direction: column;
  }

  .nav-link {
    padding: 0;
    display: block;
    color: $gray-700;

    +.nav-link {
      margin-top: 6px;
      padding-top: 6px;
      border-top: 1px dotted $gray-200;
    }

    @include hover-focus() {
      color: $az-color-primary;
    }

    &.active {
      color: $az-color-primary;
      font-weight: 500;

      @include media-breakpoint-up(lg) {
        font-weight: 400;
      }
    }
  }
}

.az-menu-sub-mega {
  top: 0 !important; //Overrideing react-bootstrap inline css
  left: 0 !important; //Overrideing react-bootstrap inline css
  right: 0 !important; //Overrideing react-bootstrap inline css
  transform: translate(0) !important; //Overrideing react-bootstrap inline css
  background-color: transparent;
  padding: 0;
  border: 0;
  position: relative !important; //Overrideing react-bootstrap inline css

  .container {
    padding: 0;

    @include media-breakpoint-down(md) {
      max-width: none;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      background-color: #fff;
      border-left: 2px solid rgba($gray-900, 0.12);
    }

    >div {
      @include media-breakpoint-up(lg) {
        display: block;
        padding: 20px;
        flex: 1;
        margin-top: 0;
      }

      @include media-breakpoint-up(xl) {
        padding: 20px 25px;
      }

      +div {
        margin-top: 25px;

        @include media-breakpoint-up(lg) {
          margin-top: 0;
          border-left: 1px solid rgba($gray-900, 0.05);
        }
      }
    }
  }

  .nav {
    min-width: 120px;
    flex-direction: column;

    @include media-breakpoint-up(xl) {
      min-width: 140px;
    }

    +.nav {
      border-top: 1px dotted $gray-200;
      margin-top: 6px;
      padding-top: 6px;

      @include media-breakpoint-up(lg) {
        margin-left: 0px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 0px;
        margin-top: 0;
      }
    }

    >span {
      display: block;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      color: $gray-900;
      letter-spacing: 0.5px;
      margin-bottom: 15px;
      margin-top: 30px;

      @include media-breakpoint-up(lg) {
        font-size: 12px;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.az-header-center {
  flex: 1;
  margin: 0 50px;
  position: relative;
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  .form-control {
    border-color: $gray-200;
    border-width: 2px;
    background-color: #fcfcfc;
    transition: none;
    height: $az-height-base + 2px;

    &:focus {
      background-color: #fff;
      border-color: $gray-400;
      box-shadow: none !important;

      +.btn {
        color: $gray-600;
      }
    }
  }

  .btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    height: $az-height-base + 2px;
    color: $gray-400;
    transition: none;
    font-size: 16px;
    padding-right: 13px;

    @include hover-focus() {
      color: $gray-900;
    }
  }
}

.az-header-right {
  display: flex;
  align-items: center;

  .btn-social {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-500;
    @include border-radius();
    @include transition($transition-base);

    @include hover-focus() {
      color: $gray-700;
    }

    i {
      font-size: 18px;
      line-height: 0;
    }

    +.btn-social {
      margin-left: 10px;
    }
  }

  .btn-buy {
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-size: 11px;
    font-weight: 500;
    background-color: $az-color-primary;
    color: #fff;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    @include border-radius();
    @include transition($transition-base);
    margin-left: 20px;

    @include hover-focus() {
      background-color: darken($indigo, 5%);
    }
  }
}

.az-header-search-link {
  font-size: 18px;
  color: $gray-700;
  line-height: 1;
  margin-right: 15px;
}

.az-header-arrow {
  font-size: 27px;
  color: $gray-900;
  display: inline-block;
  line-height: 0;

  i {
    line-height: 0;
  }

  @include hover-focus() {
    color: $gray-700;
  }
}

.az-header-message,
.az-header-notification {
  position: relative;

  >a {
    display: block;
    font-size: 24px;
    color: $gray-900;
    position: relative;
    line-height: 0.9;
    outline: none;

    &.new {
      &::before {
        content: "";
        position: absolute;
        top: -2px;
        right: 2px;
        width: 7px;
        height: 7px;
        background-color: $red;
        @include border-radius(100%);
      }
    }
  }
}

.az-header-notification {
  margin-left: 15px;

  >a {
    &.new {
      &::after {
        content: "";
        position: absolute;
        top: 47px;
        left: 50%;
        margin-left: -7px;
        width: 14px;
        height: 14px;
        border: 2px solid transparent;
        border-top-color: $gray-300; //$az-border-color;
        border-left-color: $gray-300; //$az-border-color;
        transform: rotate(45deg);
        background-color: #fff;
        z-index: 901;
        display: none;

        @include media-breakpoint-up(lg) {
          bottom: -36px;
        }
      }
    }
  }

  .dropdown-menu {
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 20px;
    border-width: 0;
    margin-top: 0;
    z-index: 900;
    @include border-radius(0);

    @include media-breakpoint-up(sm) {
      width: 300px;
      position: absolute;
      top: 28px !important; //Overrideing react-bootstrap inline css
      left: auto !important; //Overrideing react-bootstrap inline css
      right: -10px;
      bottom: auto;
      transform: none !important;
      padding: 20px 20px 15px;
      border-color: $gray-300;
      border-width: 2px;
      transform: translate(-260px, 25px) !important;
    }

    @include media-breakpoint-down(sm) {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      transform: translate(0, 0) !important;
      transform: translate(-0px, 25px) !important;
    }
  }

  .dropdown-footer {
    text-align: center;
    padding-top: 10px;
    font-size: $font-size-sm;
    border-top: 1px dotted $gray-400;
  }

  &.show {
    >a::after {
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .dropdown-menu {
      display: block;
    }
  }
}

.az-notification-title {
  font-size: 18px;
  font-weight: 700;
  color: $gray-900;
  margin-bottom: 5px;
  line-height: 1;
}

.az-notification-text {
  font-size: $font-size-sm;
  margin-bottom: 15px;
}

.az-notification-list {
  .media {
    padding: 10px 0;
    position: relative;

    +.media {
      border-top: 1px dotted $gray-400;
    }

    &.new {
      color: $gray-900;
    }

    @include hover-focus() {
      cursor: pointer;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -20px;
        bottom: -1px;
        right: -20px;
        background-color: $gray-100;
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        z-index: -1;
      }
    }
  }

  .media-body {
    margin-left: 15px;
    font-size: $font-size-sm;

    p {
      margin-bottom: 5px;
      line-height: 1.3;
    }

    strong {
      font-weight: $font-weight;
    }

    span {
      display: block;
      font-size: 11px;
      color: $gray-500;
    }
  }
}

.az-profile-menu {
  position: relative;
  margin-left: 15px;

  >.az-img-user {
    outline: none;
    width: 32px;
    height: 32px;

    &::before {
      content: "";
      position: absolute;
      bottom: -33px;
      left: 50%;
      margin-left: -7px;
      width: 14px;
      height: 14px;
      border: 2px solid transparent;
      border-top-color: $gray-300; //$az-border-color;
      border-left-color: $gray-300; //$az-border-color;
      transform: rotate(45deg);
      background-color: #fff;
      z-index: 901;
      display: none;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 20px 5px;
    border-width: 0;
    margin-top: 0;
    z-index: 900;
    @include border-radius(0);

    @include media-breakpoint-up(sm) {
      padding-top: 20px;
      position: absolute;
      top: 56px !important;
      left: auto !important;
      right: -10px;
      bottom: auto;
      transform: none !important; //Overrideing react-bootstrap inline css
      width: 230px;
      border-color: $gray-300;
      border-width: 2px;
    }

    @include media-breakpoint-down(sm) {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      transform: translate(0, 0) !important;
    }
  }

  .dropdown-item {
    position: relative;
    padding: 0;
    height: $az-height-base;
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    color: $gray-700;

    i {
      font-size: 24px;
      margin-right: 10px;
      width: 24px;
      text-align: center;

      &.typcn {
        line-height: 0.9;
      }
    }

    @include hover-focus() {
      background-color: transparent;
      color: $az-color-primary;
    }

    +.dropdown-item {
      border-top: 1px dotted $gray-400;
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &.show {
    .az-img-user::before {
      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .dropdown-menu {
      display: block;
    }
  }
}

.az-header-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .az-img-user {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
      width: 80px;
      height: 80px;
    }

    &::after {
      display: none;
    }
  }

  h6 {
    font-size: 24px;
    font-weight: 700;
    color: $gray-900;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }

  span {
    display: block;
    font-size: $font-size-sm;
  }
}

// New
.az-header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.az-header-top {
  padding: 0 20px 30px 20px;
}

.az-header-menu-show {
  .az-sidebar {
    display: block;

    @media (max-width: 1199px) {
      display: block;
      visibility: visible;
      transform: translateX(0px);
      overflow: inherit;
      z-index: 999 !important;
      left: inherit;
      right: 0;
      background: rgba($color: #000000, $alpha: 0.4);

    }

    @media (max-width:767px) {
      display: block;
      visibility: visible;
      transform: translateX(0px);
      overflow: inherit;
      z-index: 999 !important;
      left: inherit;
      right: 0;
      background: rgba($color: #000000, $alpha: 0.4);
    }

  }
}

.az-header-menu-show {
  .right-sidebar {

    @media (max-width:1199px) {
      width: 100%;
    }

    @media (max-width:767px) {
      width: 100%;
    }


  }
}

// notification style


.rs-drawer-open {
  overflow: hidden;

  .rs-drawer-backdrop {
    transition: opacity .3s ease-in;
    opacity: 1;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1050;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: unset;
  }
  
  .rs-drawer-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    transition: opacity .3s ease-in;
    max-width: 400px;
    min-width: 400px;
    background: $az-white;
    height: 100%;

    @media (max-width: 767px) {
      max-width: 100%;
      min-width: 100%;
    }

    .rs-drawer-body {
      position: relative;

      .rs-drawer-body-close {
        background: none;
        border: none;
        box-shadow: none;
        position: absolute;
        right: 15px;
        top: 15px;

        &:hover,
        &:focus {
          border: none;
          box-shadow: none;
        }

        svg {
          display: none;
        }

        &::before {
          background-image: url(../../images/close-wel.svg);
          position: absolute;
          right: 0px;
          top: 0px;
          content: '';
          width: 24px;
          height: 24px;
        }
      }

      h6 {
        font-size: $az-font-22;
        font-family: $az-heading-one;
        color: $az-black;
        padding: 12px 15px;
      }

      .notification-header {
        ul {
          padding: 0 15px;
          list-style: none;
          display: flex;
          justify-content: end;

          li {
            cursor: pointer;
            color: #000;
            font-size: $az-font-14;
            font-family: $az-heading-two;

            a {
              color: #000;
              text-decoration: none;
            }

            &:first-child {
              margin-right: 10px;
              padding-right: 10px;
              border-right: 1px solid $az-border-color;
            }
          }
        }
      }

      .notificationlisting {
        overflow-x: auto;
        height: calc(100vh - 95px);

        @media(max-width: 767px) {
          height: calc(100vh - 60px);
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 5px 0 30px;

          li {
            padding: 15px 15px 10px;
            border-bottom: 1px solid $az-border-color;

            a {
              text-decoration: none;
              color: $az-light-black;
              display: flex;

              figure {
                margin: 0;
                display: block;
                width: 42px;
                height: 42px;
                // border: 2px solid #000;
                border-radius: 50%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 100%;
                }
              }

              .content {
                width: calc(100% - 42px);
                padding-left: 15px;

                b {
                  font-family: $az-heading-para;
                  font-weight: normal;
                  font-size: $az-font-16;

                }

                p {
                  font-size: $az-font-14;
                  margin-bottom: 5px;
                }

                i {
                  font-style: normal;
                  text-align: right;
                  font-family: $az-heading-two;
                  font-weight: normal;
                  font-size: $az-font-12;
                }
              }

            }

            &:hover,
            &:active {
              background: #f5f5f5;
            }
          }

          li.unread {
            background: #f5f5f5;
          }
        }
      }


      .notificationlisting {
        scrollbar-width: 3px;
        scrollbar-color: #333 #e9e9e9;
        -webkit-scrollbar-width: 3px;
        -moz-scrollbar-width: 3px;
      }

      .notificationlisting::-webkit-scrollbar {
        width: 3px;
        height: 5px;
      }

      .notificationlisting::-webkit-scrollbar-track {
        background-clip: content-box;
        border: 0px solid transparent;
        width: 3px;
        background-color: #e9e9e9;
      }

      .notificationlisting::-webkit-scrollbar-thumb {
        background-color: #333;
      }

      .notificationlisting::-webkit-scrollbar-thumb:hover {
        background-color: #333;
      }

      .notificationlisting::-webkit-scrollbar-corner,
      .notificationlisting::-webkit-scrollbar-track {
        background-color: #e9e9e9;
        width: 3px;
      }

      [class^="message-container"] {
        /* height: 200px; */
        overflow-y: scroll;
        width: 3px;
      }

      [class^="message-container"] div {
        /* height: 400px;
        width: 200%;
        padding: 4rem;
        background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
      }





    }
  }
}

.profileViewmobile {
  display: none;

  .navMob {
    @media (max-width: 991px) {
      figure {
        width: 130px;
        height: 130px;
        border: 2px solid #000000;
        border-radius: 50%;
        margin-bottom: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }
    }

    @media (max-width: 767px) {
      figure {
        width: 130px;
        height: 130px;
        border: 2px solid #000000;
        border-radius: 50%;
        margin-bottom: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }
    }

  }

  @media (max-width: 991px) {
    display: block;
    // justify-content: center;
    margin-top: 40px;

    ul {
      list-style: none;
      padding: 0;
      margin-top: 20px;

      li {
        border-bottom: 1px solid $az-border-color;

        a {
          font-size: $az-font-14;
          font-family: $az-heading-para;
          color: $az-gray;
          padding: 10px 15px;
          width: 100%;
          display: block;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  @media (max-width: 767px) {
    display: block;
    // justify-content: center;
    margin-top: 40px;

    ul {
      list-style: none;
      padding: 0;
      margin-top: 20px;

      li {
        border-bottom: 1px solid $az-border-color;

        a {
          font-size: $az-font-14;
          font-family: $az-heading-para;
          color: $az-gray;
          padding: 10px 15px;
          width: 100%;
          display: block;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

}

.az-header-menu-show {

  .az-sidebar {

    @media (max-width: 1023px) {
      transform: translateX(0px) !important;
      transition: 0.8s !important;
      visibility: inherit !important;
      left: inherit !important;
      right: inherit !important;
      background: rgba(0, 0, 0, 0.4) !important;
      opacity: 1;
      position: fixed;
    }

    @media (max-width: 991px) {
      transform: translateX(0px) !important;
      transition: 0.8s !important;
      visibility: inherit !important;
      left: inherit !important;
      right: inherit !important;
      background: rgba(0, 0, 0, 0.4) !important;
      opacity: 1;
    }

    @media (max-width: 767px) {
      transform: translateX(0px) !important;
      transition: 0.8s !important;
      visibility: inherit !important;
      left: inherit !important;
      right: inherit !important;
      background: rgba(0, 0, 0, 0.4) !important;
      opacity: 1;
    }

  }

}

.az-sidebar {

  @media (max-width: 991px) {
    transform: translateX(0px) !important;
    transition: 0.8s !important;
    visibility: hidden !important;
    left: inherit !important;
    right: inherit !important;
    display: block !important;
    background: none !important;
    opacity: 0;
  }

  @media (max-width: 767px) {
    transform: translateX(0px) !important;
    transition: 0.8s !important;
    visibility: hidden !important;
    left: inherit !important;
    right: inherit !important;
    display: block !important;
    background: none !important;
    opacity: 0;
  }

  border-right: 1px solid $az-border-color !important;

}

// New submenu

.mobchildMenu {

  @media (max-width: 991px) {
    transition: 0.8s !important;
    transform: translateX(0px) !important;
    visibility: inherit !important;
    left: 0 !important;
    right: inherit !important;
    background: rgba(0, 0, 0, 0.4) !important;
    position: fixed;
    top: 0;
    padding-top: 65px;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  @media (max-width: 767px) {
    transition: 0.8s !important;
    transform: translateX(0px) !important;
    visibility: inherit !important;
    left: 0 !important;
    right: inherit !important;
    background: rgba(0, 0, 0, 0.4) !important;
    position: fixed;
    top: 0;
    padding-top: 65px;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .newSubmenu {
    display: none;

    @media (max-width: 1023px) {

      width: calc(100% - 350px) !important;
      transition: all 0.2s linear 0s;
      transform: translateX(0px);
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      background: $az-white;
      height: 100%;
      width: 100%;
      padding-top: 65px;
      overflow-y: auto;
      z-index: 999 !important;

      a.back {
        display: block;
        width: 100%;
        background: $az-black;
        color: $az-white;
        padding: 10px 15px;
        letter-spacing: 0.19px;
        font-family: $az-heading-para;
        font-size: $az-font-14;

        i {
          font-size: $az-font-13;
          margin-right: 5px;
        }
      }

      span.back {
        display: block;
        width: 100%;
        background: $az-black;
        color: $az-white;
        padding: 10px 15px;
        letter-spacing: 0.19px;
        font-family: $az-heading-para;
        font-size: $az-font-14;

        i {
          font-size: $az-font-13;
          margin-right: 5px;
        }
      }

      ul {
        padding: 0;

        li {
          margin: 0 !important;
          border-bottom: 1px solid $az-border-color;

          a {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
            color: $az-gray !important;
          }

          a.active {
            background: $az-eage-color;
            color: $az-black;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

    }

    @media (max-width: 991px) {

      width: calc(100% - 350px) !important;
      transition: all 0.2s linear 0s;
      transform: translateX(0px);
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      background: $az-white;
      height: 100%;
      width: 100%;
      padding-top: 65px;
      overflow-y: auto;
      z-index: 999 !important;

      a.back {
        display: block;
        width: 100%;
        background: $az-black;
        color: $az-white;
        padding: 10px 15px;
        letter-spacing: 0.19px;
        font-family: $az-heading-para;
        font-size: $az-font-14;

        i {
          font-size: $az-font-13;
          margin-right: 5px;
        }
      }

      span.back {
        display: block;
        width: 100%;
        background: $az-black;
        color: $az-white;
        padding: 10px 15px;
        letter-spacing: 0.19px;
        font-family: $az-heading-para;
        font-size: $az-font-14;

        i {
          font-size: $az-font-13;
          margin-right: 5px;
        }
      }

      ul {
        padding: 0;

        li {
          margin: 0 !important;
          border-bottom: 1px solid $az-border-color;

          a {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
            color: $az-gray !important;
          }

          a.active {
            background: $az-eage-color;
            color: $az-black;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

    }

    @media (max-width: 767px) {

      width: calc(100% - 146px) !important;
      transition: all 0.2s linear 0s;
      transform: translateX(0px);
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      background: $az-white;
      height: 100%;
      width: 100%;
      padding-top: 65px;
      overflow-y: auto;
      z-index: 999 !important;

      a.back {
        display: block;
        width: 100%;
        background: $az-black;
        color: $az-white;
        padding: 10px 15px;
        letter-spacing: 0.19px;
        font-family: $az-heading-para;
        font-size: $az-font-14;

        i {
          font-size: $az-font-13;
          margin-right: 5px;
        }
      }

      span.back {
        display: block;
        width: 100%;
        background: $az-black;
        color: $az-white;
        padding: 10px 15px;
        letter-spacing: 0.19px;
        font-family: $az-heading-para;
        font-size: $az-font-14;

        i {
          font-size: $az-font-13;
          margin-right: 5px;
        }
      }

      ul {
        padding: 0;

        li {
          margin: 0 !important;
          border-bottom: 1px solid $az-border-color;

          a {
            display: flex;
            justify-content: space-between;
            padding: 10px 15px;
            color: $az-gray !important;
          }

          a.active {
            background: $az-eage-color;
            color: $az-black;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

    }

    @media (max-width: 375px) {
      // width: calc(100% - 109px) !important;
      width: calc(100% - 146px) !important;

    }



  }

}

// .subchildToggale {
//   .mobchildMenu {
//       transform: translateX(470px) !important;
//       transition: 0.8s !important;
//     }
// }


// .subchildToggale.az-header-menu-show {
//   .mobchildMenu {
//     transform: translateX(0px) !important;
//     transition: 0.8s !important;
//   }
// }

// .mobchildMenu {
//   transform: translateX(470px) !important;
//   transition: 0.8s !important;
// }


// .subchildToggale.az-header-menu-show {
//     visibility: inherit !important;
// }

// .az-header-menu-show {
//   .subchildToggale {
//     visibility: hidden !important;
//     transition: 0.8s !important;
//   }
// }

// .innter-container {
//   .col-md-2.left {
//     width: 250px;
//     max-width: 250px;
//     flex: 0 0 250px;
//     min-height: 100vh;
//     position: absolute;
//     background: $az-white;
//     z-index: 9999;
//     box-shadow: 0px 6px 26px #ddd;
//     top: 99px;
//     height: 100%;
//     left: 0;
//   }

//   .col-md-2.hide {
//     width: 250px;
//     max-width: 250px;
//     flex: 0 0 250px;
//     min-height: calc(100vh - 70px);
//     position: absolute;
//     background: $az-white;
//     z-index: 9999;
//     box-shadow: 0px 6px 26px #ddd;
//     opacity: 0;
//     opacity: 1;

//     @media(max-width:767px) {
//       opacity: 0;
//     }
//   }

// .col-md-10.right {
//   width: 100%;
//   max-width: 100%;
//   flex: 0 0 100%;
// }
// }


.innter-container {
  .col-md-2.left {
    width: 180px;
    max-width: 180px;
    flex: 0 0 180px;
    min-height: calc(100vh - 100px);
    position: fixed;
    background: $az-white;
    z-index: 9999;
    box-shadow: none;
    left: 0;
    padding: 0 !important;
    // border-right: 1px solid $az-border-color;
    display: block;

    .nav-pills {
      padding-right: 0;
    }
  }

  .col-md-2.hide {
    width: 250px;
    max-width: 250px;
    flex: 0 0 250px;
    min-height: calc(100vh - 70px);
    position: absolute;
    background: $az-white;
    z-index: 1;
    box-shadow: 0px 6px 26px #ddd;
    opacity: 0;
    display: none;
  }

  .col-md-10.right {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .child-menu-section {
    padding-left: 0 !important;
    padding-top: 0px !important;
  }

  ul li span {
    background:#ccc ;
  }

  ul li ul li .nav-link {
    border-radius: 0 !important;
    padding: 9px 5px 9px 15px !important;
  }

}

.az-header-menu {

  .nav-link {
    .rightArrow {
      i {
        font-size: $az-font-13;
      }
    }
  }

}

.backtosubMenu {
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  // a {
  background: $az-black;
  color: $az-white;
  text-decoration: none;
  padding: 9px 5px 9px 15px;
  text-align: left;
  font-size: $az-font-14;
  font-family: $az-heading-para;
  letter-spacing: 0.19px;
  margin: 0px;
  display: block;
  width: 100%;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: $az-white;
  }

  i {
    margin-right: 5px;
    font-size: $az-font-13;
  }

  // }
}

.logo-contnet {
  display: contents !important;
}

.right-sidebar {
  .brandName h4 {

    @media(max-width: 991px) {
      padding-top: 15px;
    }

    @media(max-width: 767px) {
      padding-top: 15px;
    }
  }

  .innter-container .right {

    @media(max-width: 767px) {
      margin-top: 0 !important;
    }
  }
}